import {useEffect} from 'react';
import {useCookie, useMedia} from 'react-use';

export type UseColorModeParams = {
	initialValue?: 'dark' | 'light' | 'system';
	cookieName: string;
};

export const useColorMode = ({initialValue = 'system', cookieName}: UseColorModeParams) => {
	const [colorModeCookie, setColorModeCookie] = useCookie(cookieName);
	const colorMode = colorModeCookie || initialValue;
	const prefersDarkMode = useMedia('(prefers-color-scheme: dark)', colorMode === 'dark');

	const persistedPrefersDarkMode =
		(initialValue === 'system' && prefersDarkMode) || initialValue === 'dark' || colorMode === 'dark';

	useEffect(() => {
		if (persistedPrefersDarkMode) document.body.classList.add('dark-theme');
		else document.body.classList.remove('dark-theme');
	}, [persistedPrefersDarkMode]);

	useEffect(() => {
		if (typeof window === 'undefined') return;
		const systemMode = prefersDarkMode ? 'dark' : 'light';
		setColorModeCookie(initialValue === 'system' ? systemMode : colorMode, {
			sameSite: 'lax',
		});
	}, [colorMode, initialValue, persistedPrefersDarkMode, prefersDarkMode, setColorModeCookie]);

	return persistedPrefersDarkMode ? 'dark' : 'light';
};
